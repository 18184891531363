<template>
  <!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
  <div id="dc1f030d">
    <div class="dal-rtv">
      <router-view>
      </router-view>
    </div>
    <div class="dal-footer">
      <div id="ae920f64">
        <!-- <div id="a6740cd0">
          <img src="../assets/img-ac41715e.png"
               id="ac41715e" />
          <div id="aac02205">
            数字智能
          </div>
        </div> -->
        <div class="logo-wrapper">
          <img id="ac41715e"
               src="../assets/img-aa858367.png" />
          <span>数字智能</span>
        </div>

        <div id="a701d713">
          <div id="a3ca3f22">
            联系我们
          </div>
          <div id="a13f47b1">
            邮箱：contact@daline.com.cn
          </div>
          <div id="ad260cbb">
            武汉总部：江汉区新华路468号CFD时代财富中心4006室
          </div>
          <div id="ac750b8f">
            武汉光谷办公室：东湖高新区光谷软件园D8栋F座302室
          </div>
          <div id="ac750b8f">
            上海办公室：上海静安区北京西路968号(嘉地中心)755室
          </div>
        </div>
        <!-- <img id="a6d2de7c" src="../assets/img-a6d2de7c.png"/> -->
        <img id="a739757d"
             src="../assets/img-a739757d.png" />
        <!-- <div id="a562438b">
          <div id="a7fae711">
            关注我们
          </div>
          <div id="c9406b6d">
            微信公众号
          </div>
          <div id="cace0349">
            知乎
          </div>
        </div> -->
        <!-- <img src="../assets//img-a739757d.png"
             id="a739757d" /> -->
        <!-- <img src="../assets/img-a602e94e.png"
             id="a602e94e" /> -->
      </div>
      <div id="a89ab62d">
        <span id="ce147546">
          copyright @武汉数字智能信息科技有限公司
        </span>
      </div>
      <div id="a89ab62d2">
        <div style="margin-left:10px; padding:20px 0;">
            <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">
                    鄂ICP备2022014174号-1
                </p>
            </a>
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42018502006244" style="display:inline-block;margin-left:15px;height:20px;line-height:20px;">
                <img src="../assets/备案图标.png" style="float:left;"/>
                <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">
                    鄂公网安备 42018502006244号
                </p>
            </a>
        </div>
      </div>

    </div>
    <!-- <dal-compfloat></dal-compfloat> -->
  </div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
// import dalCompfloat from "./dal-compfloat.vue"
export default {
  components: {
    // 'dal-compfloat': dalCompfloat
  },
  data () {
    return {
      //在此注释下方添加自定义变量
    }
  },
  mounted () { },
  computed: {},
  watch: {},
  methods: {
    //在此注释下方添加自定义函数或事件
  }
}
</script>
<style lang="scss">
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#dc1f030d .dal-footer {
  padding: 1em 15vw;
  /* width: 2500px; */
  /* position: absolute; */
  /* bottom: -320px; */
  /* height: 270px; */
  font-size: calc(0.75vw + 0.75rem);
  background-color: #141620;
}

#dc1f030d .dal-rtv {
  flex: 1;
  position: relative;
}

#ae920f64 {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#a6740cd0 {
  width: 109px;
  position: relative;
  padding-top: 18px;
  display: inline-block;
  vertical-align: middle;
}

#ac41715e {
  vertical-align: middle;
}

#aac02205 {
  margin-top: 10px;
}

#aac02205 {
  text-align: left;
  color: #fff;
  font-size: 18px;
  font-family: PingFang SC;
}

#a701d713 {
  font-size: 0.6em;
  /* width: 450px; */
  position: relative;
  /* margin-left: 105px; */
  /* padding-top: 18px; */
  /* padding-bottom: 35px; */
  display: inline-block;
  vertical-align: middle;
}

#a3ca3f22 {
  color: #fff;
  font-size: 1.25em;
  font-family: PingFang SC;
}

#a13f47b1 {
  margin-top: 8px;
}

#a13f47b1 {
  text-align: left;
  color: #b7b7b7;
  // font-size: 16px;
  font-family: PingFang SC;
}

#ad260cbb {
  margin-top: 6px;
}

#ad260cbb {
  text-align: left;
  color: #b7b7b7;
  // font-size: 16px;
  font-family: PingFang SC;
}

#ac750b8f {
  margin-top: 6px;
}

#ac750b8f {
  text-align: left;
  color: #b7b7b7;
  // font-size: 16px;
  font-family: PingFang SC;
}

#a562438b {
  width: 72px;
  position: relative;
  margin-left: 100px;
  padding-top: 18px;
  padding-bottom: 63px;
  display: inline-block;
  vertical-align: middle;
}

#a7fae711 {
  text-align: left;
  color: #fff;
  font-size: 18px;
  font-family: PingFang SC;
}

#c9406b6d {
  margin-top: 6px;
}

#c9406b6d {
  text-align: left;
  color: #b7b7b7;
  font-size: 16px;
  font-family: PingFang SC;
}

#cace0349 {
  margin-top: 6px;
}

#cace0349 {
  text-align: left;
  color: #b7b7b7;
  font-size: 16px;
  font-family: PingFang SC;
}

#a739757d {
  width: 150px;
  height: 150px;
  /* margin-left: 150px; */
  /* padding-bottom: 12px; */
  display: inline-block;
  vertical-align: middle;
}

#a739757d {
  vertical-align: middle;
  /* padding-top: 18px */
}

#a602e94e {
  width: 150px;
  height: 150px;
  margin-left: 96px;
  padding-bottom: 12px;
  display: inline-block;
  vertical-align: middle;
}

#a602e94e {
  vertical-align: middle;
}

#ce147546 {
  /* margin-top: 36px; */
  /* padding-bottom: 20px; */
}

#ce147546 {
  text-align: left;
//   color: #fff;
  color:#939393;
  // font-size: 16px;
  font-family: PingFang SC;
}

/*在此注释下方添加自定义样式*/
.dal-footer {
  overflow: hidden;
}
#ce14756 {
  margin-left: 4em;
  color: #fff;
  // font-size: 16px;
  font-family: PingFang SC;
}
#a89ab62d {
  font-size: 0.6em;
  // font-size: 0.8em;
  padding-top: 0.75em;
  display: flex;
  align-items: center;
  justify-content: center;
}
#a89ab62d2 {
  font-size: 0.6em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 7rem;
  }
  span {
    margin-top: auto;
    font-size: 1.75rem;
    color: #fff;
  }
}
</style>